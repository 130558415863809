import React from "react";
import {Link} from "gatsby";
import {motion} from "framer-motion";
import {buildImageObj} from "../lib/helpers";
import {imageUrlFor} from "../lib/image-url";

const transition = {
    duration: 0.6,
    ease: [0.43, 0.13, 0.23, 0.96]
};

function ProjectPreview(props) {
    return (
        <Link to={`/work/${props.slug.current}`}>
            <div className="preview">
                <div className="preview-img">
                    <div className="preview-txt">
                        <h2 className="preview-client font-bold text-4xl tracking-widest">{props.client.clientName}</h2>
                        <h3 className="preview-title">{props.title}</h3>
                    </div>
                    <motion.div
                        className="project-img zoom"
                        style={{
                        backgroundImage: `url(${imageUrlFor(buildImageObj(props.mainImage))
                            .auto('format')
                            .width(750)
                            .height(750)
                            .url()})`
                    }}
                        transition={transition}/>
                </div>
            </div>
        </Link>
    );
}

export default ProjectPreview;