// import {Link} from "gatsby";
import React from "react";
import ProjectPreview from "./project-preview";

function ProjectPreviewGrid(props) {
    return (
        <section id="projects" className="sm:py-12 md:py-14">
            <div className="container mx-auto">
                <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-4">
                    {props.nodes && props
                        .nodes
                        .map(node => (
                            <li key={node.id}>
                                <ProjectPreview {...node}/>
                            </li>
                        ))}
                </ul>
                {/* {props.browseMoreHref && (
                    <div className="flex justify-center mt-8 mb-12 sm:mb-0">
                        <Link to={props.browseMoreHref}>
                            <button className="magnaBtn btn">
                                <span>All our Work</span>
                            </button>
                        </Link>
                    </div>
                )} */}
            </div>
        </section>
    );
}

ProjectPreviewGrid.defaultProps = {
    title: "",
    nodes: [],
    browseMoreHref: ""
};

export default ProjectPreviewGrid;
