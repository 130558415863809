import React from "react";
import {graphql} from "gatsby";
import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import {mapEdgesToNodes, filterOutDocsWithoutSlugs} from "../lib/helpers";
import PageParallax from "../components/page-parallax";

export const query = graphql `
query WorkPageQuery {
  projects: allSanityWork(  
    sort: {fields: [publishedAt], order: DESC}
    filter: {slug: {current: {ne: null}}, publishedAt: {ne: null}}
  ) {
    edges {
      node {
        id
        mainImage {
          ...Image
        }
        title
        client {
          clientName
        }
        slug {
          current
        }
      }
    }
  }
  sanityWorkPage {
    workHero {
      ...Image
    }
  }
}
`;

const WorkPage = props => {
    const {data} = props;
    const projectNodes = data && data.projects && mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs);
    return (
        <Layout>
            <SEO title="Our Work" image={data.sanityWorkPage.workHero}/>
            <PageParallax image={data.sanityWorkPage.workHero} title="Our Work"/>
            {projectNodes && projectNodes.length > 0 && <ProjectPreviewGrid nodes={projectNodes}/>}
        </Layout>
    );
};

export default WorkPage;
